import React from "react";
import { Link } from "gatsby";
import moment from "moment";
import classnames from "classnames";
import { GatsbyImage } from "gatsby-plugin-image"
import config from "../../../data/SiteConfig";

export default class Card extends React.Component {
  render() {
    const {
      horizontal,
      titleSize,
      contentHeight,
      slug,
      title,
      description,
      cover,
      category,
      updatedAt
    } = this.props;

    return (
      <Link to={`/${slug}`}>
        <div className={classnames("card", { "is-horizontal": horizontal })}>
          <div className="card-image">
            <figure>
              <GatsbyImage image={cover.gatsbyImageData} alt={title} title={title} />
            </figure>
          </div>
          <div
            className="card-content is-flex"
            style={{ minHeight: contentHeight }}
          >
            <div className="content">
              <p className="is-uppercase is-size-7 has-text-danger has-text-weight-semibold has-letter-spacing">
                {category}
              </p>
              <p className={`title ${titleSize} is-spaced`}>{title}</p>
              <p className="subtitle is-size-6">{description}</p>
            </div>
            <time
              dateTime={moment(updatedAt).format("YYYY-MM-DD")}
              className="is-size-7"
            >
              {moment(updatedAt).format(config.dateFormat)}
            </time>
          </div>
        </div>
      </Link>
    );
  }
}
