import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../layout";
import SEO from "../components/SEO/SEO";
import Card from "../components/Card/Card";
import SaleBanner from "../components/SaleBanner/SaleBanner";
import config from "../../data/SiteConfig";
import "./index.scss";

export default class IndexPage extends React.Component {
  render() {
    const {
      data: {
        allContentfulPost: { edges: postEdges }
      },
      location
    } = this.props;

    return (
      <Layout location={location}>
        <Helmet title={config.siteTitle} />
        <SEO />
        <section className="section">
          <div className="container">
            <div className="columns is-multiline is-centered">
              <div className="column is-12">
                <h1 className="title">Final Sales Alerts</h1>
                <SaleBanner />
              </div>
            </div>
          </div>
        </section>
        {/* Featured posts */}
        <section className="section">
          <div className="container">
            <div className="columns is-multiline is-centered">
              {postEdges.map(postEdge => {
                if (postEdge.node.isFeatured) {
                  return (
                    <div className="column is-12" key={postEdge.node.slug}>
                      <Card
                        horizontal
                        titleSize="is-size-3-desktop is-size-4-tablet is-size-5"
                        slug={postEdge.node.slug}
                        title={postEdge.node.title}
                        description={postEdge.node.description}
                        cover={postEdge.node.cover}
                        category={postEdge.node.category.title}
                        updatedAt={postEdge.node.updatedAt}
                      />
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
        </section>

        {/* All posts except featured post */}
        <section className="section">
          <div className="container">
            <div className="columns is-multiline is-centered">
              {postEdges.map(postEdge => {
                if (!postEdge.node.isFeatured) {
                  return (
                    <div
                      className="column is-4-desktop is-6"
                      key={postEdge.node.slug}
                    >
                      <Card
                        titleSize="is-size-5"
                        contentHeight={280}
                        slug={postEdge.node.slug}
                        title={postEdge.node.title}
                        description={postEdge.node.description}
                        cover={postEdge.node.cover}
                        category={postEdge.node.category.title}
                        updatedAt={postEdge.node.updatedAt}
                      />
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const query = graphql`
  query {
    allContentfulPost(sort: { fields: [updatedAt], order: DESC }, limit: 2000) {
      edges {
        node {
          slug
          title
          description
          cover {
            gatsbyImageData(
              width: 800
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
          category {
            title
          }
          updatedAt
          isFeatured
        }
      }
    }
  }
`;
